

.project-creation-content {
  .client-autocomplete {
    width: 100%;
  }
}

.layout-public {
  .project-creation-content {
    background-color: #F3F3F3;
    padding-bottom: 2rem;

    form {
      margin: auto;
      width: 60rem;
    }
  }
}

.project-overview-header {
  h2 {
    a {
      white-space: nowrap;
    }
  }
}

.project-content {
  .card-header.docsya{
    margin-bottom: 0rem;
  }
  .project-detail {
    padding-top: 2rem;

    .map {
      width: 100%;
    }

    .card-header {
      .brain{
        font-size: 2rem;
        color: var(--bs-gray-500);
      }
      .brain.success{
        color: #3f82cc;
      }
    }

    .card-header, .card-footer {
      h1 {
        font-size: 2rem;
        small {
          font-size: 0.8rem;
        }
      }
    }

    .project-detail-header {
      label {
        display: inline-block;
        min-width: 10rem;
      }

      .actions-list {
        clear: both;
      }
    }

    .log-container {
      font-size: 0.8rem;
    }

    .form-valorisation {
      font-size: small;

      label, .input-group-addon, input {
        font-size: small;
      }

      input {
        width: 4rem;
        text-align: center;
      }
    }
  }
  .info-bulle-docsya {
    position: relative;
    display: inline-block;
    a{
      display: flex;
      text-decoration: none;
    };
    .info-bulle-text {
        visibility: hidden;
        width: 460px;
        height: auto;
        background-color: rgba(51, 51, 51, 1);
        color: #fff;
        border-radius: 6px;
        padding: 10px;
        position: absolute;
        z-index: 1;
        bottom: 105%;
        right: 0%;
        opacity: 0;
        transition: opacity 0.3s, visibility 0.3s;
        text-align: left;
    }
  }
  .info-bulle-docsya:hover .info-bulle-text {
      visibility: visible;
      opacity: 1;
  }
  .info-bulle {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left:1px;
    .info-bulle-text {
      visibility: hidden;
      width: 460px;
      height: auto;
      background-color: rgba(51, 51, 51, 1);
      color: #fff;
      border-radius: 6px;
      padding: 15px;
      position: absolute;
      z-index: 1;
          bottom: 125%; /* Adjust this value to control distance from the icon */
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s, visibility 0.3s;
          text-align: left;
          .info {
              color: rgba(67, 178, 219, 1);
              display: inline
          }
    }
  }
  .info-bulle:hover .info-bulle-text {
      visibility: visible;
      opacity: 1;
  }
}
.comments-container {
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .comments {
    flex: 1 1 90%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: scroll;
    max-height: 30rem;
    min-height: 5rem;

    .comment-container {
      flex: 1 1 auto; clear: both;

      .comment {
        border-radius: 5px;
        padding: 8px; margin: 8px 12px;
          max-width: 70%;  /* this will make it not exceed 80% and then wrap */
          position: relative; transition: background-color 0.5s;

          span {
            white-space: break-spaces;
          }

          a {
            color: $white
          }

          &.show-more {
            .comment-content {
              overflow: hidden;
              max-height: 6rem;              
            }
          }
        }

        .comment-content {
          white-space: break-spaces;
        }
      }

      .comment-left {
        div {
          background-color: #AAAAAA;
          float: left;
          color: #fff;

          .comment-date {
            color: hsla(204, 7%, 42%, 1)
          }
        }
      }

      .comment-cofrac-left {
        div {
          background-color: #f0ad4e;
          float: left;
          color: #fff;

          .comment-date {
            color: hsla(204, 7%, 42%, 1)
          }
        }
      }

      .comment-right {
        div {
          background-color: #26AECC;
          float: right;
          color: #fff;

          .comment-date {
            color: hsla(204, 7%, 82%, 1)
          }
        }
      }

      .comment-cofrac-right {
        div {
          background-color: #f0ad4e;
          float: right;
          color: #fff;

          .comment-date {
            color: hsla(204, 7%, 82%, 1)
          }
        }
      }

      .comment-left div::before {
        content: ''; position: absolute;
        width: 0; height: 0; left: -8px; top: 8px;
        border-top: 4px solid transparent;
        border-right: 8px solid #ccc;
        border-bottom: 4px solid transparent;
      }

      .comment-cofrac-left div::before {
        content: ''; position: absolute;
        width: 0; height: 0; left: -8px; top: 8px;
        border-top: 4px solid transparent;
        border-right: 8px solid #ccc;
        border-bottom: 4px solid transparent;
      }

      .comment-right div::after {
        content: ''; position: absolute;
        width: 0; height: 0; right: -8px; bottom: 8px;
        border-top: 4px solid transparent;
        border-left: 8px solid #26AECC;
        border-bottom: 4px solid transparent;
      }

      .comment-cofrac-right div::after {
        content: ''; position: absolute;
        width: 0; height: 0; right: -8px; bottom: 8px;
        border-top: 4px solid transparent;
        border-left: 8px solid #f0ad4e;
        border-bottom: 4px solid transparent;
      }
    }

    form {
      margin-top: 5px;

    }
  }

  .document-container {
    background-color: #fff;

    .document-upload-choice {
      label {
        position: relative;
        cursor: pointer;
      }

      .form-control-file {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .project-operation-content {
    .project-operation-wrapper {
      margin-top: 2rem;
    }
  }

  .project-operation-details {
    background-color: #fff;

    .operation-selection {
      select {
        width: 100%;
      }
    }

    .project-operation-info-details {
      ul.infos {
        box-shadow: 1px 1px 5px #888888;
      }
      ul {
        li {
          padding: 0.5rem 1rem;
          background-color: #fffeea;
          color: #856404;

          &.alert {
            color: #4d0000;
            background-color: #ffe6e6;

			& li {
				color: #4d0000;
				background-color: #ffe6e6;
			}
          }

          &.warning {
            color: #856404;
            background-color: #fffeea;
          }
        }
      }
    }

    .detail-label {
      color: #636c72;
    }

    .project-operation-detail {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
      box-shadow: 1px 1px 20px #ccc;
      padding: 0.5rem 0;
    }

    .group {
      margin-bottom: 1rem;
      h3 {
        color: #ccc;
        font-size: 1.25rem;
      }
      .item {
        border: 1px solid #ccc;
        margin-bottom: 1rem;
        padding: 10px;
        box-shadow: 1px 1px 20px #ccc;

        .project-operation-detail {
          border: 0;
          box-shadow: none;
        }

        .project-operation-detail:last-child {
          margin-bottom: 0;
        }


      }

      .item-remove {
        text-align: right;
        a {
          color: #ccc;
        }
      }

      .group-add {
        text-align: right;
        a {
          color: #ccc;
        }
      }
    }

    .long-detail-info {
      text-align: justify;
      line-height: 1rem;
      font-size: 0.9rem;
    }
  }

  .project-operation-content, .project-content {
    .more-details {
      padding: 0;

      .more-details-toggle {
        .btn {
          font-size: 2rem;
          padding: 0;
          color: #ccc;
        }
      }

      .section {
        font-size: 80%;
        position: relative;
        background-color: white;
        padding: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(0,0,0,0.125);
        border-top: 1px solid rgba(0,0,0,0.125);

        &.date-payment-estimation .input-group {
          position: relative;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: stretch;
                /* width: 100%; */
                width: 10rem;
              }
              
              .edit {
                position: absolute;
                font-size: 1.5rem;
                right: 1rem;
                top: 0.5rem;
              }

              .saving {
                position: absolute;
                right: 0.5rem;
                bottom: 0.5rem;
              }

              .cee-group-label {
                color: #ccc;
                font-size: 1.25rem;
              }

              input:disabled {
                background-color: #e9ecef;
                opacity: 1;
              }
            }
          }
        }

        .layout-public .project-operation-content {
          background-color: #F3F3F3;
          padding-bottom: 2rem;
        }

        .timeline {
          position: relative;
          width: 100%;
          margin: 0 auto;

          .list {
            display: flex;
            margin: 0;
            padding: 0;

            .item {
              position: relative;
              display: inline-block;
              margin: 0;
              padding: 0;
              width: 13%;
              height: 46px;
              white-space: nowrap;
              text-align: center;

              &:first-child {
                border-radius: .25rem 0 0 .25rem;
              }

              .item-text {
                font-size: 15px;
                color: #fff;

                &:focus {
                  outline: none;
                }
              }
            }
          }

          .chevron {
            display:block;
            width: 1.1em;
            height: 100%;
            position:absolute;
            top: 0;
            right: -1em;;
            z-index: 10;
          }
          .chevron:before,
          .chevron:after {
            display:block;
            content:"";
            width:0;
            height:0em;
            border-style:solid;
            position:absolute;
          }
          .chevron:before {
            right:0;
            border-width:1.8em 0 1.8em 1em;
            border-color:transparent transparent transparent  #fff;    
          }
          .chevron:after {
            left:0;
            border-width:1.8em 0 1.8em 1em;
            border-color:transparent transparent transparent #1cb9a4;    
          }
        }